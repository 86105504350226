import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";

import styles from "./hero.module.css";
import { SectionWidth } from "../../sectionWidth/sectionWidth";
import { useSelector } from "react-redux";

const Hero = ({ activity, setActivity }) => {
  const {
    connectionState: { address },
  } = useSelector((state) => state.connection);

  const { totalOwned, totalStaked } = useSelector((state) => state.herostats);
  return (
    <SectionWidth className={styles.hero}>
      <div className={styles.details}>
        <img
          src={require("../../../assets/diapernft.png")}
          alt="Pangs Collection"
        />
        <div>
          <h4>
            {address.slice(0, 7)}...
            {address.slice(address.length - 7, address.length)}
          </h4>
          <CopyToClipboard text={address} onCopy={() => alert("copied")}>
            <img src={require("../../../assets/copy.png")} alt="copy address" />
          </CopyToClipboard>
        </div>
      </div>
      <div className={styles.stats}>
        <div className={styles.row}>
          <div>
            <span>Total Items</span>
            <p>{totalOwned}</p>
          </div>
          <div>
            <span>Total Staked</span>
            <p>{totalStaked}</p>
          </div>
        </div>
        <div className={styles.row}>
          <div>
            <span>Amount Traded</span>
            <div>
              <p>48k</p>
              <img src={require("../../../assets/eth.png")} alt="eth" />
            </div>
          </div>
          <div>
            <span>Estimated Portfolio Value</span>
            <div>
              <p>4.98</p>
              <img src={require("../../../assets/eth.png")} alt="eth" />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.menu}>
        <li
          onClick={() => setActivity(false)}
          className={!activity ? styles.active : undefined}
        >
          Items
        </li>
        <li
          onClick={() => setActivity(true)}
          className={activity ? styles.active : undefined}
        >
          Activity
        </li>
      </div>
    </SectionWidth>
  );
};

export default Hero;
