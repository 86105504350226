import React from "react";

import styles from "./button.module.css";
import { useNavigate } from "react-router";
const Button = ({ title, path }) => {
  const navigate = useNavigate()
  return <button onClick={()=>navigate(path)} className={styles.button}>{title}</button>;
};

export default Button;
