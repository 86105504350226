export const NFTCONTRACT = "0x6499Ff0Acd0d955d3914ac8376Eaf81364E70D78";
export const STAKECONTRACT = "0x72289B592937b81694E395C432Ee700cbd7D452c";
export const REWARDTOKEN = "0x788896C6095554E28Ae3AFF3c2D8097055768c5b";
export const MARKETPLACE = "0xD4a6191754fA1A3A80D58C0594A3aB453e3C9c67"

export const NFTABI = [{
        inputs: [{
            internalType: "address payable",
            name: "_receiver",
            type: "address",
        }, ],
        stateMutability: "nonpayable",
        type: "constructor",
    },
    {
        anonymous: false,
        inputs: [{
                indexed: true,
                internalType: "address",
                name: "owner",
                type: "address",
            },
            {
                indexed: true,
                internalType: "address",
                name: "approved",
                type: "address",
            },
            {
                indexed: true,
                internalType: "uint256",
                name: "tokenId",
                type: "uint256",
            },
        ],
        name: "Approval",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [{
                indexed: true,
                internalType: "address",
                name: "owner",
                type: "address",
            },
            {
                indexed: true,
                internalType: "address",
                name: "operator",
                type: "address",
            },
            {
                indexed: false,
                internalType: "bool",
                name: "approved",
                type: "bool",
            },
        ],
        name: "ApprovalForAll",
        type: "event",
    },
    {
        inputs: [{
                internalType: "address",
                name: "to",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "tokenId",
                type: "uint256",
            },
        ],
        name: "approve",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [{
            internalType: "uint256",
            name: "tokenId",
            type: "uint256",
        }, ],
        name: "burn",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "lock",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        anonymous: false,
        inputs: [{
                indexed: true,
                internalType: "address",
                name: "_to",
                type: "address",
            },
            {
                indexed: true,
                internalType: "uint256",
                name: "_tokenId",
                type: "uint256",
            },
        ],
        name: "Migration",
        type: "event",
    },
    {
        inputs: [{
            internalType: "uint256",
            name: "_amount",
            type: "uint256",
        }, ],
        name: "mintNFT",
        outputs: [],
        stateMutability: "payable",
        type: "function",
    },
    {
        anonymous: false,
        inputs: [{
                indexed: true,
                internalType: "address",
                name: "previousOwner",
                type: "address",
            },
            {
                indexed: true,
                internalType: "address",
                name: "newOwner",
                type: "address",
            },
        ],
        name: "OwnershipTransferred",
        type: "event",
    },
    {
        inputs: [],
        name: "pause",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        anonymous: false,
        inputs: [{
            indexed: false,
            internalType: "address",
            name: "account",
            type: "address",
        }, ],
        name: "Paused",
        type: "event",
    },
    {
        inputs: [],
        name: "renounceOwnership",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        anonymous: false,
        inputs: [{
                indexed: true,
                internalType: "address",
                name: "from",
                type: "address",
            },
            {
                indexed: true,
                internalType: "address",
                name: "to",
                type: "address",
            },
            {
                indexed: true,
                internalType: "uint256",
                name: "tokenId",
                type: "uint256",
            },
        ],
        name: "Transfer",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [{
            indexed: false,
            internalType: "address",
            name: "account",
            type: "address",
        }, ],
        name: "Unpaused",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [{
                indexed: true,
                internalType: "address",
                name: "devAddress",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "amount",
                type: "uint256",
            },
        ],
        name: "WithdrawFees",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [{
                indexed: true,
                internalType: "address",
                name: "devAddress",
                type: "address",
            },
            {
                indexed: false,
                internalType: "address",
                name: "tokenAddress",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "tokenId",
                type: "uint256",
            },
        ],
        name: "WithdrawWrongNfts",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [{
                indexed: true,
                internalType: "address",
                name: "devAddress",
                type: "address",
            },
            {
                indexed: false,
                internalType: "address",
                name: "tokenAddress",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "amount",
                type: "uint256",
            },
        ],
        name: "WithdrawWrongTokens",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [{
                indexed: true,
                internalType: "address",
                name: "receiveraddress",
                type: "address",
            },
            {
                indexed: false,
                internalType: "string",
                name: "leaguename",
                type: "string",
            },
        ],
        name: "mintEvent",
        type: "event",
    },
    {
        inputs: [{
            internalType: "address",
            name: "to",
            type: "address",
        }, ],
        name: "safeMint",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [{
                internalType: "address",
                name: "from",
                type: "address",
            },
            {
                internalType: "address",
                name: "to",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "tokenId",
                type: "uint256",
            },
        ],
        name: "safeTransferFrom",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [{
                internalType: "address",
                name: "from",
                type: "address",
            },
            {
                internalType: "address",
                name: "to",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "tokenId",
                type: "uint256",
            },
            {
                internalType: "bytes",
                name: "data",
                type: "bytes",
            },
        ],
        name: "safeTransferFrom",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [{
                internalType: "address",
                name: "operator",
                type: "address",
            },
            {
                internalType: "bool",
                name: "approved",
                type: "bool",
            },
        ],
        name: "setApprovalForAll",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [{
            internalType: "address payable",
            name: "_receiver",
            type: "address",
        }, ],
        name: "setReceiver",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [{
            internalType: "uint16",
            name: "_royalty",
            type: "uint16",
        }, ],
        name: "setRoyalty",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [{
            internalType: "address",
            name: "_royaltyAddress",
            type: "address",
        }, ],
        name: "setRoyaltyAddress",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [{
                internalType: "uint256",
                name: "tokenId",
                type: "uint256",
            },
            {
                internalType: "string",
                name: "uri",
                type: "string",
            },
        ],
        name: "setURI",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [{
                internalType: "address",
                name: "from",
                type: "address",
            },
            {
                internalType: "address",
                name: "to",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "tokenId",
                type: "uint256",
            },
        ],
        name: "transferFrom",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [{
            internalType: "address",
            name: "newOwner",
            type: "address",
        }, ],
        name: "transferOwnership",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "unlock",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "unpause",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [{
            internalType: "address",
            name: "_router",
            type: "address",
        }, ],
        name: "updateRouter",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "withdraw",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [{
                internalType: "address",
                name: "_tokenContract",
                type: "address",
            },
            {
                internalType: "uint256[]",
                name: "_id",
                type: "uint256[]",
            },
        ],
        name: "withdrawNFT",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [{
            internalType: "address",
            name: "_tokenContract",
            type: "address",
        }, ],
        name: "withdrawTokens",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [{
            internalType: "address",
            name: "owner",
            type: "address",
        }, ],
        name: "balanceOf",
        outputs: [{
            internalType: "uint256",
            name: "",
            type: "uint256",
        }, ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "baseExtension",
        outputs: [{
            internalType: "string",
            name: "",
            type: "string",
        }, ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "baseURI",
        outputs: [{
            internalType: "string",
            name: "",
            type: "string",
        }, ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [{
            internalType: "address",
            name: "",
            type: "address",
        }, ],
        name: "devFees",
        outputs: [{
            internalType: "uint256",
            name: "",
            type: "uint256",
        }, ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [{
            internalType: "uint256",
            name: "tokenId",
            type: "uint256",
        }, ],
        name: "getApproved",
        outputs: [{
            internalType: "address",
            name: "",
            type: "address",
        }, ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [{
                internalType: "address",
                name: "owner",
                type: "address",
            },
            {
                internalType: "address",
                name: "operator",
                type: "address",
            },
        ],
        name: "isApprovedForAll",
        outputs: [{
            internalType: "bool",
            name: "",
            type: "bool",
        }, ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "name",
        outputs: [{
            internalType: "string",
            name: "",
            type: "string",
        }, ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [{
            internalType: "string",
            name: "",
            type: "string",
        }, ],
        name: "nfts",
        outputs: [{
                internalType: "string",
                name: "id",
                type: "string",
            },
            {
                internalType: "uint256",
                name: "fee",
                type: "uint256",
            },
            {
                internalType: "string",
                name: "uri",
                type: "string",
            },
            {
                internalType: "uint256",
                name: "percentage",
                type: "uint256",
            },
            {
                internalType: "string",
                name: "name",
                type: "string",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [{
            internalType: "address",
            name: "_owner",
            type: "address",
        }, ],
        name: "Owned",
        outputs: [{
            internalType: "uint256[]",
            name: "",
            type: "uint256[]",
        }, ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "owner",
        outputs: [{
            internalType: "address",
            name: "",
            type: "address",
        }, ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [{
            internalType: "uint256",
            name: "tokenId",
            type: "uint256",
        }, ],
        name: "ownerOf",
        outputs: [{
            internalType: "address",
            name: "",
            type: "address",
        }, ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "paused",
        outputs: [{
            internalType: "bool",
            name: "",
            type: "bool",
        }, ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "router",
        outputs: [{
            internalType: "address",
            name: "",
            type: "address",
        }, ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "royalty",
        outputs: [{
            internalType: "uint256",
            name: "",
            type: "uint256",
        }, ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "royaltyAddress",
        outputs: [{
            internalType: "address",
            name: "",
            type: "address",
        }, ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [{
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_salePrice",
                type: "uint256",
            },
        ],
        name: "royaltyInfo",
        outputs: [{
                internalType: "address",
                name: "_receiver",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "royaltyAmount",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [{
            internalType: "bytes4",
            name: "interfaceId",
            type: "bytes4",
        }, ],
        name: "supportsInterface",
        outputs: [{
            internalType: "bool",
            name: "",
            type: "bool",
        }, ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "symbol",
        outputs: [{
            internalType: "string",
            name: "",
            type: "string",
        }, ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [{
            internalType: "uint256",
            name: "index",
            type: "uint256",
        }, ],
        name: "tokenByIndex",
        outputs: [{
            internalType: "uint256",
            name: "",
            type: "uint256",
        }, ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [{
            internalType: "uint256",
            name: "_id",
            type: "uint256",
        }, ],
        name: "tokenExists",
        outputs: [{
            internalType: "bool",
            name: "",
            type: "bool",
        }, ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [{
                internalType: "address",
                name: "owner",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "index",
                type: "uint256",
            },
        ],
        name: "tokenOfOwnerByIndex",
        outputs: [{
            internalType: "uint256",
            name: "",
            type: "uint256",
        }, ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [{
            internalType: "uint256",
            name: "Id",
            type: "uint256",
        }, ],
        name: "tokenURI",
        outputs: [{
            internalType: "string",
            name: "",
            type: "string",
        }, ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "totalSupply",
        outputs: [{
            internalType: "uint256",
            name: "",
            type: "uint256",
        }, ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [{
            internalType: "address",
            name: "",
            type: "address",
        }, ],
        name: "whiteListed",
        outputs: [{
            internalType: "bool",
            name: "",
            type: "bool",
        }, ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "whitelistedOnly",
        outputs: [{
            internalType: "bool",
            name: "",
            type: "bool",
        }, ],
        stateMutability: "view",
        type: "function",
    },
];

export const STAKEABI = [{
        inputs: [{
            internalType: "address",
            name: "_rewardWallet",
            type: "address",
        }, ],
        stateMutability: "nonpayable",
        type: "constructor",
    },
    {
        anonymous: false,
        inputs: [{
                indexed: true,
                internalType: "address",
                name: "token",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "oldDripRate",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "newDripRate",
                type: "uint256",
            },
        ],
        name: "ChangeDripRate",
        type: "event",
    },
    {
        inputs: [{
            internalType: "bytes32",
            name: "stakeId",
            type: "bytes32",
        }, ],
        name: "claim",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [{
            internalType: "address",
            name: "account",
            type: "address",
        }, ],
        name: "claimAll",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        anonymous: false,
        inputs: [{
                indexed: true,
                internalType: "bytes32",
                name: "stakeId",
                type: "bytes32",
            },
            {
                indexed: true,
                internalType: "address",
                name: "owner",
                type: "address",
            },
            {
                indexed: true,
                internalType: "uint256",
                name: "amount",
                type: "uint256",
            },
        ],
        name: "ClaimRewards",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [{
            indexed: true,
            internalType: "address",
            name: "nftContract",
            type: "address",
        }, ],
        name: "DeniedNFTContract",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [{
                indexed: true,
                internalType: "address",
                name: "token",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "dripRate",
                type: "uint256",
            },
        ],
        name: "DeniedRewardToken",
        type: "event",
    },
    {
        inputs: [{
            internalType: "address",
            name: "nftContract",
            type: "address",
        }, ],
        name: "denyNFT",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [{
            internalType: "address",
            name: "token",
            type: "address",
        }, ],
        name: "denyRewardToken",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        anonymous: false,
        inputs: [{
                indexed: true,
                internalType: "uint256",
                name: "oldTime",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "newTime",
                type: "uint256",
            },
        ],
        name: "MinimumStakingTimeChanged",
        type: "event",
    },
    {
        inputs: [{
                internalType: "address",
                name: "operator",
                type: "address",
            },
            {
                internalType: "address",
                name: "from",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "tokenId",
                type: "uint256",
            },
            {
                internalType: "bytes",
                name: "data",
                type: "bytes",
            },
        ],
        name: "onERC721Received",
        outputs: [{
            internalType: "bytes4",
            name: "",
            type: "bytes4",
        }, ],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        anonymous: false,
        inputs: [{
                indexed: true,
                internalType: "address",
                name: "previousOwner",
                type: "address",
            },
            {
                indexed: true,
                internalType: "address",
                name: "newOwner",
                type: "address",
            },
        ],
        name: "OwnershipPulled",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [{
                indexed: true,
                internalType: "address",
                name: "previousOwner",
                type: "address",
            },
            {
                indexed: true,
                internalType: "address",
                name: "newOwner",
                type: "address",
            },
        ],
        name: "OwnershipPushed",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [{
                indexed: true,
                internalType: "address",
                name: "previousOwner",
                type: "address",
            },
            {
                indexed: true,
                internalType: "address",
                name: "newOwner",
                type: "address",
            },
        ],
        name: "OwnershipTransferred",
        type: "event",
    },
    {
        inputs: [{
            internalType: "address",
            name: "nftContract",
            type: "address",
        }, ],
        name: "permitNFT",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [{
                internalType: "address",
                name: "token",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "amountOfTokenPerDayPerNFT",
                type: "uint256",
            },
        ],
        name: "permitRewardToken",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        anonymous: false,
        inputs: [{
            indexed: true,
            internalType: "address",
            name: "nftContract",
            type: "address",
        }, ],
        name: "PermittedNFTContract",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [{
            indexed: true,
            internalType: "address",
            name: "token",
            type: "address",
        }, ],
        name: "PermittedRewardToken",
        type: "event",
    },
    {
        inputs: [],
        name: "pullOwnership",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [{
            internalType: "address",
            name: "newOwner",
            type: "address",
        }, ],
        name: "pushOwnership",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        anonymous: false,
        inputs: [{
                indexed: false,
                internalType: "address",
                name: "operator",
                type: "address",
            },
            {
                indexed: false,
                internalType: "address",
                name: "from",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "tokenId",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "bytes",
                name: "data",
                type: "bytes",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "gas",
                type: "uint256",
            },
        ],
        name: "ReceivedERC721",
        type: "event",
    },
    {
        inputs: [],
        name: "renounceOwnership",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        anonymous: false,
        inputs: [{
                indexed: true,
                internalType: "address",
                name: "oldRewardWallet",
                type: "address",
            },
            {
                indexed: true,
                internalType: "address",
                name: "newRewardWallet",
                type: "address",
            },
        ],
        name: "RewardWalletChanged",
        type: "event",
    },
    {
        inputs: [{
            internalType: "uint256",
            name: "minimumStakingTime",
            type: "uint256",
        }, ],
        name: "setMinimumStakingTimeForRewards",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [{
                internalType: "address",
                name: "token",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "amountOfTokenPerDayPerNFT",
                type: "uint256",
            },
        ],
        name: "setRewardTokenDripRate",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [{
            internalType: "address",
            name: "wallet",
            type: "address",
        }, ],
        name: "setRewardWallet",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [{
                internalType: "contract IERC721",
                name: "nftContract",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "tokenId",
                type: "uint256",
            },
            {
                internalType: "contract IERC20",
                name: "rewardToken",
                type: "address",
            },
        ],
        name: "stake",
        outputs: [{
            internalType: "bytes32",
            name: "",
            type: "bytes32",
        }, ],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        anonymous: false,
        inputs: [{
                indexed: true,
                internalType: "address",
                name: "owner",
                type: "address",
            },
            {
                indexed: true,
                internalType: "address",
                name: "nftContract",
                type: "address",
            },
            {
                indexed: true,
                internalType: "uint256",
                name: "tokenId",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "address",
                name: "rewardToken",
                type: "address",
            },
        ],
        name: "Stake",
        type: "event",
    },
    {
        inputs: [{
            internalType: "address",
            name: "newOwner",
            type: "address",
        }, ],
        name: "transferOwnership",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [{
            internalType: "bytes32",
            name: "stakeId",
            type: "bytes32",
        }, ],
        name: "unstake",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        anonymous: false,
        inputs: [{
                indexed: true,
                internalType: "address",
                name: "owner",
                type: "address",
            },
            {
                indexed: true,
                internalType: "address",
                name: "nftContract",
                type: "address",
            },
            {
                indexed: true,
                internalType: "uint256",
                name: "tokenId",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "address",
                name: "rewardToken",
                type: "address",
            },
        ],
        name: "UnStake",
        type: "event",
    },
    {
        inputs: [{
            internalType: "address",
            name: "account",
            type: "address",
        }, ],
        name: "claimable",
        outputs: [{
            components: [{
                    internalType: "bytes32",
                    name: "stakeId",
                    type: "bytes32",
                },
                {
                    internalType: "address",
                    name: "rewardToken",
                    type: "address",
                },
                {
                    internalType: "uint256",
                    name: "amount",
                    type: "uint256",
                },
            ],
            internalType: "struct PangStaking.ClaimableInfo[]",
            name: "",
            type: "tuple[]",
        }, ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [{
            internalType: "address",
            name: "nftContract",
            type: "address",
        }, ],
        name: "isPermittedNFT",
        outputs: [{
            internalType: "bool",
            name: "",
            type: "bool",
        }, ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [{
            internalType: "address",
            name: "token",
            type: "address",
        }, ],
        name: "isPermittedRewardToken",
        outputs: [{
            internalType: "bool",
            name: "",
            type: "bool",
        }, ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "MINIMUM_STAKING_TIME_FOR_REWARDS",
        outputs: [{
            internalType: "uint256",
            name: "",
            type: "uint256",
        }, ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "nfts",
        outputs: [{
            internalType: "address[]",
            name: "",
            type: "address[]",
        }, ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "owner",
        outputs: [{
            internalType: "address",
            name: "",
            type: "address",
        }, ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [{
            internalType: "address",
            name: "account",
            type: "address",
        }, ],
        name: "rewardHistory",
        outputs: [{
                internalType: "address[]",
                name: "_rewardTokens",
                type: "address[]",
            },
            {
                internalType: "uint256[]",
                name: "_rewardsPaid",
                type: "uint256[]",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [{
            internalType: "address",
            name: "",
            type: "address",
        }, ],
        name: "rewardsPaid",
        outputs: [{
            internalType: "uint256",
            name: "",
            type: "uint256",
        }, ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [{
            internalType: "address",
            name: "",
            type: "address",
        }, ],
        name: "rewardTokenDripRate",
        outputs: [{
            internalType: "uint256",
            name: "",
            type: "uint256",
        }, ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "rewardTokens",
        outputs: [{
            internalType: "address[]",
            name: "",
            type: "address[]",
        }, ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "rewardWallet",
        outputs: [{
            internalType: "address",
            name: "",
            type: "address",
        }, ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [{
            internalType: "contract IERC20",
            name: "token",
            type: "address",
        }, ],
        name: "runway",
        outputs: [{
                internalType: "uint256",
                name: "_balance",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_dripRatePerSecond",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_stakeCount",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_runRatePerSecond",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_runRatePerDay",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_runwaySeconds",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_runwayDays",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [{
            internalType: "address",
            name: "account",
            type: "address",
        }, ],
        name: "staked",
        outputs: [{
            components: [{
                    internalType: "bytes32",
                    name: "stakeId",
                    type: "bytes32",
                },
                {
                    internalType: "address",
                    name: "owner",
                    type: "address",
                },
                {
                    internalType: "contract IERC721",
                    name: "nftContract",
                    type: "address",
                },
                {
                    internalType: "uint256",
                    name: "tokenId",
                    type: "uint256",
                },
                {
                    internalType: "uint256",
                    name: "stakedTimestamp",
                    type: "uint256",
                },
                {
                    internalType: "uint256",
                    name: "lastClaimTimestamp",
                    type: "uint256",
                },
                {
                    internalType: "contract IERC20",
                    name: "rewardToken",
                    type: "address",
                },
                {
                    internalType: "uint256",
                    name: "dripAmount",
                    type: "uint256",
                },
                {
                    internalType: "string",
                    name: "uri",
                    type: "string",
                },
            ],
            internalType: "struct PangStaking.StakedNFT[]",
            name: "",
            type: "tuple[]",
        }, ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [{
            internalType: "bytes32",
            name: "",
            type: "bytes32",
        }, ],
        name: "stakedNFTs",
        outputs: [{
                internalType: "bytes32",
                name: "stakeId",
                type: "bytes32",
            },
            {
                internalType: "address",
                name: "owner",
                type: "address",
            },
            {
                internalType: "contract IERC721",
                name: "nftContract",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "tokenId",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "stakedTimestamp",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "lastClaimTimestamp",
                type: "uint256",
            },
            {
                internalType: "contract IERC20",
                name: "rewardToken",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "dripAmount",
                type: "uint256",
            },
            {
                internalType: "string",
                name: "uri",
                type: "string",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [{
            internalType: "bytes32",
            name: "stakeId",
            type: "bytes32",
        }, ],
        name: "stakedtokenId",
        outputs: [{
            internalType: "uint256",
            name: "",
            type: "uint256",
        }, ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [{
            internalType: "bytes32",
            name: "",
            type: "bytes32",
        }, ],
        name: "stakedTokenIds",
        outputs: [{
            internalType: "uint256",
            name: "",
            type: "uint256",
        }, ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [{
            internalType: "address",
            name: "account",
            type: "address",
        }, ],
        name: "stakeIds",
        outputs: [{
            internalType: "bytes32[]",
            name: "",
            type: "bytes32[]",
        }, ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [{
            internalType: "address",
            name: "",
            type: "address",
        }, ],
        name: "stakesPerRewardToken",
        outputs: [{
            internalType: "uint256",
            name: "",
            type: "uint256",
        }, ],
        stateMutability: "view",
        type: "function",
    },
];

export const MARKETPLACEABI = [{
        "inputs": [{
            "internalType": "uint256",
            "name": "_feePercent",
            "type": "uint256"
        }],
        "stateMutability": "nonpayable",
        "type": "constructor"
    },
    {
        "anonymous": false,
        "inputs": [{
                "indexed": false,
                "internalType": "uint256",
                "name": "itemId",
                "type": "uint256"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "nft",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "price",
                "type": "uint256"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "seller",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "buyer",
                "type": "address"
            }
        ],
        "name": "Bought",
        "type": "event"
    },
    {
        "inputs": [{
                "internalType": "contract IERC721",
                "name": "_nft",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "_tokenId",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "_price",
                "type": "uint256"
            }
        ],
        "name": "makeItem",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "anonymous": false,
        "inputs": [{
                "indexed": false,
                "internalType": "uint256",
                "name": "itemId",
                "type": "uint256"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "nft",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "price",
                "type": "uint256"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "seller",
                "type": "address"
            }
        ],
        "name": "Offered",
        "type": "event"
    },
    {
        "inputs": [{
            "internalType": "uint256",
            "name": "_itemId",
            "type": "uint256"
        }],
        "name": "purchaseItem",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    },
    {
        "anonymous": false,
        "inputs": [{
                "indexed": false,
                "internalType": "uint256",
                "name": "itemId",
                "type": "uint256"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "nft",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "price",
                "type": "uint256"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "seller",
                "type": "address"
            }
        ],
        "name": "Unlist",
        "type": "event"
    },
    {
        "inputs": [{
            "internalType": "uint256",
            "name": "_itemId",
            "type": "uint256"
        }],
        "name": "unListItem",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "feeAccount",
        "outputs": [{
            "internalType": "address payable",
            "name": "",
            "type": "address"
        }],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "feePercent",
        "outputs": [{
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
        }],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [{
            "internalType": "uint256",
            "name": "_itemId",
            "type": "uint256"
        }],
        "name": "getTotalPrice",
        "outputs": [{
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
        }],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "itemCount",
        "outputs": [{
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
        }],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [{
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
        }],
        "name": "items",
        "outputs": [{
                "internalType": "uint256",
                "name": "itemId",
                "type": "uint256"
            },
            {
                "internalType": "contract IERC721",
                "name": "nft",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "price",
                "type": "uint256"
            },
            {
                "internalType": "address payable",
                "name": "seller",
                "type": "address"
            },
            {
                "internalType": "bool",
                "name": "sold",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    }
]