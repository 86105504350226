import React from "react";
import Cards from "../../components/catalogue/cards/cards";
import styles from "./catalogue.module.css";

const Catalogue = () => {
  const nfts = [
    {
      name: "JAZMYND CLOWN",
      description:
        "*Meteor Level - Rarity is 1 of 49 for this trait… there is ONLY 49 TOTAL NFTs available with 1 trait in the Meteor Level Category! If you were lucky enough to draw this trait you’ve just received 19 points on the rarity chart! ",
      story: `JAZMYND CLOWN, a female pangolin with unparalleled intelligence and strategic prowess, is the true mastermind behind the Wu Pang Clan's global mission to promote goodwill and harmony between nature and humanity. Her cunning mind and sharp wit make her the brains behind the entire operation, orchestrating the calculated distribution of kindness and genuineness that the Wu Pang Clan aims to present to the world. Her efforts remain shrouded in secrecy, as she operates behind the scenes, pulling the strings to ensure the Clan's success. Jazmynd Clown influence extends far and wide, and her reputation as a master facilitator is renowned among the pangolin community. Her unwavering dedication to the Clan's cause has earned her a legion of devoted followers, and she is sought after by all who recognize her remarkable abilities. Her leadership and vision have propelled the Wu Pang Clan to new heights, and her unwavering commitment to doing good for both nature and humanity serves as a beacon of hope for those who share her values. With Jazmynd Clown at the helm, the Wu Pang Clan's mission of promoting kindness, compassion, and positive change in the world is sure to leave an indelible mark on the world for generations to come. `,
      color: "#352673",
      img: require("../../assets/art/19 CLOWN SHOW.png"),
    },
    {
      name: "TONY MONTANA",
      description:
        "*Asteroid Level – Rarity is 1 of 75 for this trait… there is ONLY 150 TOTAL NFTs available, consisting of 2 traits in the Asteroid Level Category! If you were lucky enough to draw this trait you’ve just received 15 points on the rarity chart! ",
      story: `As a young pangolin, Tony had a penchant for thrill-seeking and indulgence. He developed an insatiable appetite for a dangerous and addictive white powder that eventually consumed him. Despite warnings from his fellow pangolins and the pangolin community, Tony couldn't resist the lure of the illicit substance. His downward spiral led him into the dark underworld of rival pangolin gangs, where he became embroiled in a vicious and deadly gunfight. Tragically, Tony's story came to a premature end, serving as a cautionary tale of the perils of addiction and the consequences of breaking the three main life rules for any pangolin - don't lie, don't cheat, don't steal. His demise serves as a somber reminder of the importance of making wise choices and staying true to the values that uphold the integrity of the pangolin community.`,
      color: "#C87466",
      img: require("../../assets/art/15 THE COCAINE’GOLIN.png"),
    },
    {
      name: "MASTER SHIRO",
      description:
        "*Asteroid Level – Rarity is 1 of 75 for this trait… there is ONLY 150 TOTAL NFTs available, consisting of 2 traits in the Asteroid Level Category! If you were lucky enough to draw this trait you’ve just received 15 points on the rarity chart!",
      story: `Master Shiro, the Ninja Pangolin, is a legendary figure who rules over the secret Wu Pang Clan with unparalleled stealth and wisdom. Among the 61 pangs in the clan, Shiro is known as the stealthiest of them all, a master of the shadows who moves with grace and precision. Despite his formidable skills, Master Shiro rules with a soft and compassionate fist, always prioritizing fairness, empathy, and justice. He leads by example, inspiring his fellow ninjas with his unwavering commitment to the art of ninjutsu and his compassionate approach to leadership. Under his guidance, the Wu Pang Clan has become a formidable force for good, protecting the weak and upholding justice in the shadows. Master Shiro's legendary reputation as a ninja leader is matched only by his unwavering dedication to the principles of honor and compassion, making him a true icon among the ninja community. `,
      color: "#2B9480",
      img: require("../../assets/art/15 NINJA_GOLIN.png"),
    },
    {
      name: "FREDDIE",
      description:
        "*Diamond Level – Rarity is 1 of 132 for this trait… there is ONLY 396 TOTAL NFTs available, consisting of 3 traits in the Diamond Category! If you were lucky enough to draw this trait you’ve just received 10 points on the rarity chart",
      story: `Freddy the Lovable Pangolin is known far and wide for his unwavering attachment to his beloved teddy bear, Eddie. Since he was just a baby, Freddy has carried Eddie with him wherever he goes, never letting go of his cherished companion. Eddie has been through thick and thin with Freddy, accompanying him on his adventures, providing comfort during challenging times, and always being there as a trusted confidant. Freddy's bond with Eddie is unbreakable, and he proudly shows off his teddy bear wherever he goes. Despite growing up, Freddy's love for Eddie has never waned, and the duo is inseparable. Their heartwarming friendship serves as a reminder of the power of unconditional love and the enduring bond between a Pangolin and his beloved teddy bear.`,
      color: "#262D40",
      img: require("../../assets/art/10 TEDDY BEAR.png"),
    },

    {
      name: "SKYLES",
      description:
        "*Diamond Level – Rarity is 1 of 132 for this trait… there is ONLY 396 TOTAL NFTs available, consisting of 3 traits in the Diamond Category! If you were lucky enough to draw this trait you’ve just received 10 points on the rarity chart ",
      story: `Skyles the Skateboarding Prodigy is a Pangolin who's taken the skateboarding world by storm. Ever since he first stepped on a skateboard, Skyles has been captivated by the sport, drawing inspiration from the legendary skater Stoney Rocks. With unmatched skill, determination, and a burning passion for skateboarding, Skyles has quickly risen to prominence, earning a reputation as a prodigy on the board. He spends countless hours practicing his tricks, pushing the boundaries of what's possible, and working tirelessly to honor the legacy of his skateboarding idol. Skyles' talent and dedication have earned him a devoted following of fellow skaters and fans alike, and he's become a beacon of inspiration for aspiring skaters everywhere. With his eyes set on becoming a skateboarding legend in his own right, Skyles is determined to make his mark on the sport and leave a lasting legacy that pays homage to his hero, Stoney Rocks. `,
      color: "#352673",
      img: require("../../assets/art/10 SKATEBOARD.png"),
    },
    {
      name: "JERRY CARE’CIA",
      description:
        "*Diamond Level – Rarity is 1 of 132 for this trait… there is ONLY 396 TOTAL NFTs available, consisting of 3 traits in the Diamond Category! If you were lucky enough to draw this trait you’ve just received 10 points on the rarity chart ",
      story: `Jerry the Joyful Pangolin is a legendary figure known far and wide for spreading love and joy wherever he goes. Whether he's hiking through dense trails, grooving at an EDM concert, or simply enjoying the wilderness, Jerry's infectious energy and positive vibes are unmatched. With his friendly demeanor and genuine kindness, Jerry leaves a trail of smiles and laughter wherever he travels. His zest for life and unwavering optimism inspire others to embrace the joy in every moment and appreciate the beauty of nature and music. Jerry's legendary reputation as a bringer of joy has earned him a special place in the hearts of everyone he meets, and his unique ability to spread love and positivity makes him a one-of-a-kind Pangolin.`,
      color: "#C87466",
      img: require("../../assets/art/10 MAGIC MUSHROOMS.png"),
    },
    {
      name: "DOUG THE DAD",
      description:
        "*Platinum Level – Rarity is 1 of 144 for this trait… there is ONLY 720 TOTAL NFTs available, consisting of 5 traits in the Platinum Category! If you were lucky enough to draw this trait you’ve just received 7 points on the rarity chart ",
      story: `Meet "Dapper Dad Doug," the epitome of a dorky dad Pangolin. With his signature white sneakers, hiked up jeans, and a tucked-in white T-shirt, Doug is the epitome of dad fashion. He's always prepared with dad jokes and puns, and he loves nothing more than embarrassing his kids with his goofy dance moves. Doug takes his role as a dad seriously, making sure his pangolin family is well taken care of and always putting their needs first. He's the ultimate chauffeur, soccer coach, and cheerleader for his kids, and his love and dedication to his family are unmatched. Despite his dorky fashion sense and dad jokes, Doug's unconditional love, support, and sense of humor make him the coolest dad in his family's eyes, and he's proud to rock his signature dad look with confidence.`,
      color: "#2B9480",
      img: require("../../assets/art/7 TYPICAL DAD OUTFIT.png"),
    },
    {
      name: "TANNER",
      description:
        "*Platinum Level – Rarity is 1 of 144 for this trait… there is ONLY 720 TOTAL NFTs available, consisting of 5 traits in the Platinum Category! If you were lucky enough to draw this trait you’ve just received 7 points on the rarity chart",
      story: `"Tanner the Snow Shredder" is a Pangolin who's known for his unmatched snowboarding skills and jaw-dropping tricks. At the young age of 15, Tanner burst onto the snowboarding scene at the prestigious "Winter Pangolin Games" in Aspen, Colorado in 2019, leaving the crowd in awe with his breathtaking performances. With a natural talent for snowboarding and a fearless approach to the slopes, Tanner pushed the boundaries of what was thought possible for a Pangolin on a snowboard, pulling off tricks that no one had ever seen before. His daring stunts and innovative style earned him widespread recognition and a reputation as a trailblazer in the snowboarding community. Tanner continues to inspire fellow Pangolins and snowboarders alike with his remarkable skills, and his incredible performances are etched in snowboarding history as a testament to his unmatched talent and boundless potential.`,
      color: "#262D40",
      img: require("../../assets/art/7 SNOWBOARD.png"),
    },
    {
      name: "LONDYN",
      description:
        "*Platinum Level – Rarity is 1 of 144 for this trait… there is ONLY 720 TOTAL NFTs available, consisting of 5 traits in the Platinum Category! If you were lucky enough to draw this trait you’ve just received 7 points on the rarity chart ",
      story: `"Londyn the Coral Crusader" is a female Pangolin who has dedicated her life to the conservation of coral reefs. From a young age, she developed a deep love for the ocean and its mesmerizing coral reefs. Londyn's passion led her to become an expert snorkeling diver and tropical fish connoisseur, with an unparalleled knowledge of marine life and their habitats. She has traveled the world, exploring the most breathtaking coral reefs, and tirelessly advocates for their protection and preservation. Londyn's unwavering commitment to conservation has earned her a reputation as a leading voice in marine conservation efforts, and her tireless efforts to raise awareness about the importance of coral reefs and their fragile ecosystems inspire others to join her cause. She's a true champion for the oceans and a beacon of hope for the future of our coral reefs.`,
      color: "#352673",
      img: require("../../assets/art/7 SNORKELING.png"),
    },
    {
      name: "TRIANNA",
      description:
        "*Platinum Level – Rarity is 1 of 144 for this trait… there is ONLY 720 TOTAL NFTs available, consisting of 5 traits in the Platinum Category! If you were lucky enough to draw this trait you’ve just received 7 points on the rarity chart ",
      story: `"Trianna the Adventurer" is a Pangolin with an extraordinary tale of survival on the high seas. Once upon a time, she found herself on a sinking ship, and with quick thinking, she grabbed a life saver and set adrift in the vast ocean. For days and nights, she floated through stormy weather and calm waters, navigating by the stars and relying on her instincts to survive. Despite her valiant efforts, Trianna never found her way back to land, and her journey became a legendary tale of resilience and determination. Her story inspires others with her unwavering spirit and the indomitable will to survive in the face of adversity, a true symbol of courage and survival against all odds.`,
      color: "#C87466",
      img: require("../../assets/art/7 LIFE SAVER.png"),
    },
    {
      name: "CONE’HEAD CHUCK",
      description:
        "*Platinum Level – Rarity is 1 of 144 for this trait… there is ONLY 720 TOTAL NFTs available, consisting of 5 traits in the Platinum Category! If you were lucky enough to draw this trait you’ve just received 7 points on the rarity chart ",
      story: `Meet "Conehead Chuck," the mischievous and adventurous Pangolin who's known for his antics on the road. Chuck has always been fascinated with traffic cones, especially the ones used in construction zones. One day, he couldn't resist the temptation and borrowed his big brother Chuck's construction cone to wear as a hat. From then on, he became notorious for his traffic-cone hijinks, disrupting traffic and causing commotion with his playful antics. While Chuck's behavior may be mischievous, his playful spirit and curiosity are contagious, and he's become a local legend among his fellow pangolins for his daring escapades. Despite the occasional chaos he creates, Chuck's antics bring a smile to everyone's face and add a touch of lightheartedness to the hustle and bustle of everyday life. `,
      color: "#2B9480",
      img: require("../../assets/art/7 CONE HEAD.png"),
    },
    {
      name: "BLUE COLLAR BUCK ",
      description:
        "*Gold Level – Rarity is 1 of 140 for this trait… there is ONLY 1260 TOTAL NFTs available, consisting of 9 traits in the Gold Category! If you were lucky enough to draw this trait you’ve just received 5 points on the rarity chart",
      story: `Introducing "Blue Collar Buck," the diligent and hardworking Pangolin who spends his days toiling as a construction worker. Buck is known for his unwavering work ethic and commitment to getting the job done right. He's often seen on construction sites, wearing his hard hat and wielding his tools with precision and skill. Despite the physical challenges he faces, Buck takes pride in his work and finds fulfillment in seeing the tangible results of his labor. However, he often goes unnoticed and underappreciated, as his contributions are sometimes overlooked. Nevertheless, Buck remains dedicated to his craft and continues to pour his heart and soul into every task he undertakes. His story is a testament to the unsung heroes of the working class, who work tirelessly to build and shape the world around them with little fanfare, but with unwavering dedication and determination.`,
      color: "#262D40",
      img: require("../../assets/art/5 TRAFFIC VEST.png"),
    },
    {
      name: "MOONSHINE JOE",
      description:
        "*Gold Level – Rarity is 1 of 140 for this trait… there is ONLY 1260 TOTAL NFTs available, consisting of 9 traits in the Gold Category! If you were lucky enough to draw this trait you’ve just received 5 points on the rarity chart",
      story: `Meet "Moonshine Joe," the rugged and independent Pangolin who's made a name for himself in the Appalachian mountains of America as a master distiller of homemade moonshine. Born and raised in the heart of the Appalachian wilderness, Joe developed a deep love for the land and its traditions. He honed his skills in the craft of moonshining, perfecting his recipes and techniques over the years, and his potent concoctions are sought after by those in the know. Joe's self-sufficient and resourceful nature has earned him a reputation as a true American original, and his moonshine is a testament to his unwavering dedication to his craft. With his adventurous spirit and love for the mountains, Moonshine Joe's story is a legendary tale that echoes through the hills and hollers of the Appalachians.`,
      color: "#352673",
      img: require("../../assets/art/5 REDNECK.png"),
    },
    {
      name: "ADMIRAL THORN",
      description:
        "*Gold Level – Rarity is 1 of 140 for this trait… there is ONLY 1260 TOTAL NFTs available, consisting of 9 traits in the Gold Category! If you were lucky enough to draw this trait you’ve just received 5 points on the rarity chart ",
      story: `"Admiral Thorn," the respected and weathered Pangolin who once ruled the seas as a renowned pirate, only to be betrayed by his own son, Captain Skurge. Thorn was a legendary figure in the pirate world, known for his strategic acumen, fierce loyalty to his crew, and his prized parrot, Polly. However, his heart was broken when his son, Skurge, stole Polly and set off on his own path of piracy, leaving Thorn devastated and betrayed. Despite this betrayal, Thorn remained a respected and feared figure in the pirate community, known for his unwavering sense of justice and his determination to bring his son to account. Thorn's story serves as a cautionary tale of the complexities of family and the consequences of betrayal, a tale that echoes through pirate lore and serves as a reminder that even the mightiest can fall to treachery.`,
      color: "#C87466",
      img: require("../../assets/art/5 PIRATE.png"),
    },
    {
      name: "GUS",
      description:
        "*Gold Level – Rarity is 1 of 140 for this trait… there is ONLY 1260 TOTAL NFTs available, consisting of 9 traits in the Gold Category! If you were lucky enough to draw this trait you’ve just received 5 points on the rarity chart",
      story: `Meet "Party Animal Gus," the life of the party among Pangolins, who never misses a birthday celebration. Hailing from the lush jungles of South America, Gus has a reputation for being the ultimate partygoer, always ready to join in the festivities and celebrate with his fellow pangolins. With his vibrant personality and infectious enthusiasm, Gus is known to bring the fun wherever he goes. He's often spotted with a party hat on his head, and he's known for his dance moves that light up the dance floor. Gus's presence at any birthday celebration is a guarantee for a good time, and his outgoing nature and party spirit have earned him a special place in the hearts of his fellow pangolins as the life of every party.`,
      color: "#2B9480",
      img: require("../../assets/art/5 PARTY TIME.png"),
    },
    {
      name: "CAPTAIN SKURGE",
      description:
        "*Gold Level – Rarity is 1 of 140 for this trait… there is ONLY 1260 TOTAL NFTs available, consisting of 9 traits in the Gold Category! If you were lucky enough to draw this trait you’ve just received 5 points on the rarity chart ",
      story: `Introducing "Captain Skurge," the daring and mischievous Pangolin who sailed the seas as a notorious pirate, known for his cunning and his penchant for stealing parrots. Hailing from the shores of the Caribbean, Skurge was a fearless and cunning pirate who sailed the high seas with his crew. Among his many exploits, one of his most infamous acts was stealing a parrot from his fellow pangolin pirate, Captain Squawk, and making it his own. Skurge's pirate adventures were legendary, and his daring escapades kept him one step ahead of the authorities. Despite his roguish ways, Skurge was also known for his charismatic charm and sharp wit, which earned him a loyal following among his crew. To this day, his story is whispered among pirates and his reputation as the parrot-stealing Pangolin captain lives on in pirate lore. `,
      color: "#262D40",
      img: require("../../assets/art/5 PARROT.png"),
    },
    {
      name: "LOLLIPOP LILLY",
      description:
        "*Gold Level – Rarity is 1 of 140 for this trait… there is ONLY 1260 TOTAL NFTs available, consisting of 9 traits in the Gold Category! If you were lucky enough to draw this trait you’ve just received 5 points on the rarity chart ",
      story: `Meet "Lollipop Lilly," the sweet-toothed Pangolin who's never seen without a lollipop in her hand. From the lush rainforests of Central Africa, Lilly developed a love for all things sugary at an early age. She's known far and wide for her insatiable craving for lollipops, and she always has one in her paw to enjoy. Her unique style combines her love for sugary treats with her playful and energetic personality, making her a beloved figure among her fellow Pangolins and fans alike. Despite her sugary indulgence, Lilly is a skilled forager and defender of her territory, and she uses her charm and wit to navigate the jungle with a lollipop in hand, spreading sweetness wherever she goes. `,
      color: "#352673",
      img: require("../../assets/art/5 LOLLIPOP.png"),
    },
    {
      name: "KING KARL",
      description:
        "*Gold Level – Rarity is 1 of 140 for this trait… there is ONLY 1260 TOTAL NFTs available, consisting of 9 traits in the Gold Category! If you were lucky enough to draw this trait you’ve just received 5 points on the rarity chart",
      story: `Introducing "King Karl," the regal Pangolin who reigns over the savannah with his majestic presence and wears his crown like a true king. Born into a lineage of Pangolin royalty, Karl was raised with the utmost reverence for tradition and nobility. He ascended to the throne of his kingdom at a young age, inheriting the responsibilities of leadership with grace and dignity. Known for his wisdom and poise, King Karl is admired by his subjects and respected by his peers. He carries himself with an air of royalty, wearing his crown with pride, a symbol of his noble heritage and his unwavering commitment to his kingdom and its people. King Karl's majestic aura and unwavering leadership have earned him a place in the annals of Pangolin history as a true king among kings.`,
      color: "#C87466",
      img: require("../../assets/art/5 CROWN.png"),
    },
    {
      name: "BILLY BLING",
      description:
        "*Gold Level – Rarity is 1 of 140 for this trait… there is ONLY 1260 TOTAL NFTs available, consisting of 9 traits in the Gold Category! If you were lucky enough to draw this trait you’ve just received 5 points on the rarity chart",
      story: `Meet Billy Bling” the Pangolin! The chart-topping rap sensation with a love for all things shiny. Hailing from the dense jungles of Southeast Asia, Bling Pangolin rose to fame with his unique style that fuses his signature tongue-twisting lyrics with his undeniable charm and swagger. Billy Bling’s music is a reflection of his adventurous spirit and his journey from obscurity to stardom, with his Diamond neck bling serving as a symbol of his success. With millions of fans worldwide, Bling Pangolin continues to rock the rap scene with his unforgettable performances and unapologetic bling, leaving a trail of awe-inspired fans in his wake.`,
      color: "#2B9480",
      img: require("../../assets/art/5 BLING.png"),
    },
    {
      name: "DR STELLA",
      description:
        "*Gold Level – Rarity is 1 of 140 for this trait… there is ONLY 1260 TOTAL NFTs available, consisting of 9 traits in the Gold Category! If you were lucky enough to draw this trait you’ve just received 5 points on the rarity chart ",
      story: `Dr. Stella Pangolin is a renowned astronaut who has made significant contributions to space exploration and research. Born on a research station on Mars, she developed a deep fascination with the stars from a young age, which eventually led her to pursue a career in space science. Dr. Pangolin's unwavering determination and expertise in astrobiology and exoplanet research have earned her a prominent place in the space community, making her a respected figure among her peers. She has participated in multiple space missions, including the historic Mars colonization program, where she conducted groundbreaking research on the potential for microbial life on the red planet. Dr. Stella Pangolin continues to inspire the next generation of space explorers with her exceptional achievements and unwavering passion for unraveling the mysteries of the universe. `,
      color: "#262D40",
      img: require("../../assets/art/5 ASTRONAUT SUIT.png"),
    },

    {
      name: "JEZZAH",
      description:
        "*Silver Level – Rarity is 1 of 142 for this trait… there is ONLY 2698 TOTAL NFTs available, consisting of 19 traits in the Silver Level! If you were lucky enough to draw this trait you’ve just received 3 points on the rarity chart",
      story: `Jezzah the DJ Pangolin is a renowned music maestro known for his electrifying beats and genre-defying performances. Born with an innate love for rhythm and melody, Jezzah honed his skills through years of spinning records and creating his own unique soundscapes. With his signature mix of deep house, funky breaks, and exotic world music, Jezzah has captivated audiences around the globe, leaving them begging for more. What sets him apart is his dynamic partnership with his twin sister Rizzah, who is also a talented DJ. Together, they form an unstoppable DJ duo, bringing a dynamic synergy to their shows that leaves crowds in awe. Their combined passion for music and showmanship has earned them a dedicated following, making them a force to be reckoned with in the world of DJing. `,
      color: "#352673",
      img: require("../../assets/art/3 TURNTABLES.png"),
    },
    {
      name: "REX",
      description:
        "*Silver Level – Rarity is 1 of 142 for this trait… there is ONLY 2698 TOTAL NFTs available, consisting of 19 traits in the Silver Level! If you were lucky enough to draw this trait you’ve just received 3 points on the rarity chart",
      story: `Rex the Pangolin is an eccentric creature who stands out among his pangolin peers with his wild imagination. Convinced that he is a fearsome Tyrannosaurus… Rex roams the savannah with a confident swagger, imagining himself as the king of the prehistoric era. Despite his diminutive size and lack of sharp teeth, Rex is undeterred, often playfully stomping his feet and letting out small roars, much to the amusement of his fellow pangolins. Rex's largerthan-life personality and unshakable belief in his dino-identity make him a unique and beloved figure in his pangolin community, where he continues to entertain and inspire with his boundless imagination. `,
      color: "#C87466",
      img: require("../../assets/art/3 T-REX.png"),
    },
    {
      name: "STAN",
      description:
        "*Silver Level – Rarity is 1 of 142 for this trait… there is ONLY 2698 TOTAL NFTs available, consisting of 19 traits in the Silver Level! If you were lucky enough to draw this trait you’ve just received 3 points on the rarity chart",
      story: `Stan the Pangolin is a laid-back beach aficionado who spends his days lounging in the warm sand, soaking up the sun's rays while wearing his favorite pair of beach shorts. With his cool and relaxed attitude, Stan is the envy of the beach as he flawlessly executes the art of sun tanning. However, Stan recently experienced a setback when he lost his beloved beach ball, his trusted companion for beach games and fun. Despite this loss, Stan remains determined to make the most of his beach days, spreading good vibes and enjoying the sun-kissed shores with his signature style and carefree spirit.`,
      color: "#2B9480",
      img: require("../../assets/art/3 SWIM TRUNKS.png"),
    },
    {
      name: "QUINN ",
      description:
        "*Silver Level – Rarity is 1 of 142 for this trait… there is ONLY 2698 TOTAL NFTs available, consisting of 19 traits in the Silver Level! If you were lucky enough to draw this trait you’ve just received 3 points on the rarity chart",
      story: `Quinn is an adventurous pangolin known for her unmatched speed and thrill-seeking personality. Born with a natural affinity for the sky, Quinn took to the air from an early age and quickly gained a reputation as the fastest mammal in the skydiving world. With her sleek scales and aerodynamic form, she's a natural-born glider, effortlessly soaring through the clouds with grace and agility. Quinn has become a legend among skydivers, setting new records and pushing the boundaries of what's possible in the world of extreme sports. Her fearlessness, determination, and love for adrenalinepumping adventures have made her a beloved icon in the skydiving community, inspiring others to reach for the stars and chase their wildest dreams.`,
      color: "#262D40",
      img: require("../../assets/art/3 Sky Diver.png"),
    },

    {
      name: "PATTY",
      description:
        "*Silver Level – Rarity is 1 of 142 for this trait… there is ONLY 2698 TOTAL NFTs available, consisting of 19 traits in the Silver Level! If you were lucky enough to draw this trait you’ve just received 3 points on the rarity chart ",
      story: `Patty the Pangolin is a unique creature with a flair for adventure. Born with an insatiable curiosity and a love for the sea, Patty quickly discovered her natural affinity for swimming and free diving! Her trusty scuba flippers, custom-made to fit her scaly body, gave her the speed and agility of a dolphin in the water. Patty's underwater exploits were legendary, as she fearlessly explored the depths of the ocean, encountering a wide array of marine life and uncovering hidden treasures. With her sleek form and lightning-fast reflexes, Patty was known as the fastest pangolin in the ocean, and her daring escapades and underwater acrobatics captured the hearts of many admirers around the world.`,
      color: "#352673",
      img: require("../../assets/art/3 SCUBA FLIPPERS.png"),
    },
    {
      name: "POLKA",
      description:
        "*Silver Level – Rarity is 1 of 142 for this trait… there is ONLY 2698 TOTAL NFTs available, consisting of 19 traits in the Silver Level! If you were lucky enough to draw this trait you’ve just received 3 points on the rarity chart",
      story: `Polka the Pangolin is a quirky, lovable creature with an insatiable fondness for her polka dot pajamas and slippers. From a young age, Polka developed a penchant for all things polka dot, and her wardrobe soon became a dazzling display of spots. Whether she's curled up in her tree hollow, lounging on a sunny branch, or even venturing out on a moonlit night, you can always count on Polka to be clad in her cherished polka dot ensemble. Her unique sense of style has made her the talk of the jungle, and she takes great pride in her fashion-forward choices. With her playful personality and penchant for patterned pajamas, Polka has become a beloved and iconic figure in the animal kingdom. `,
      color: "#C87466",
      img: require("../../assets/art/3 Pajamas.png"),
    },
    {
      name: "GUNTHER",
      description:
        "*Silver Level – Rarity is 1 of 142 for this trait… there is ONLY 2698 TOTAL NFTs available, consisting of 19 traits in the Silver Level! If you were lucky enough to draw this trait you’ve just received 3 points on the rarity chart",
      story: `Gunther the teenage pangolin is a unique character in the animal kingdom. Despite his age, he never quite outgrew his infantile tendencies and can often be found curled up in his tree hollow, sucking on his trusty pacifier. Gunther's penchant for childish comforts extends beyond just his pacifier, as he's been known to collect plush toys and blanket forts in his cozy den. While other pangolins have matured into their adult roles, Gunther remains forever young at heart, with his pacifier serving as a constant source of comfort and nostalgia in his wild adventures.`,
      color: "#2B9480",
      img: require("../../assets/art/3 PACIFIER.png"),
    },
    {
      name: "Camo",
      description:
        "*Silver Level – Rarity is 1 of 142 for this trait… there is ONLY 2698 TOTAL NFTs available, consisting of 19 traits in the Silver Level! If you were lucky enough to draw this trait you’ve just received 3 points on the rarity chart ",
      story: `Meet Camo, the elusive pangolin who is known for his uncanny  
      ability to blend into his surroundings with his masterful  
      camouflage skills. Born and raised in the densest forests of  
      Borneo, Camo has honed his natural talent to perfection, making  
      him virtually invisible to both predators and humans alike. His  
      keen senses and sharp instincts have helped him navigate the  
      wilderness undetected, earning him a reputation as a true  
      phantom of the jungle. Despite his elusive nature, Camo is known  
      for his gentle disposition and love for feasting on ants and  
      termites. His mysterious presence and hidden lifestyle continue to intrigue wildlife enthusiasts  and researchers alike, as they strive to catch a glimpse of this elusive pangolin in action. `,
      color: "#262D40",
      img: require("../../assets/art/3 MILITARY.png"),
    },
    {
      name: "JACK",
      description:
        "*Silver Level – Rarity is 1 of 142 for this trait… there is ONLY 2698 TOTAL NFTs available, consisting of 19 traits in the Silver Level! If you were lucky enough to draw this trait you’ve just received 3 points on the rarity chart ",
      story: `Jack the Lumberjack Pangolin is renowned for his skill in felling  
      trees and building log cabins deep in the heart of the forest. With  
      his burly frame and sharp claws, he's a force to be reckoned with.  
      However, one fateful day, while he was diligently working on his  
      latest cabin project, disaster struck – his trusty axe went missing.  
      Despite his frantic efforts to locate it, the axe remained elusive,  
      leaving poor Jack in a bind. With his construction plans halted, Jack  
      now roams the woods, determined to find his missing axe and  
      complete his cabin, knowing that a cozy home in the wilderness is  
      just a few swings away.
      `,
      color: "#352673",
      img: require("../../assets/art/3 LUMBERJACK.png"),
    },
    {
      name: "PIPPA",
      description:
        "*Silver Level – Rarity is 1 of 142 for this trait… there is ONLY 2698 TOTAL NFTs available, consisting of 19 traits in the Silver Level! If you were lucky enough to draw this trait you’ve just received 3 points on the rarity chart",
      story: `Meet Pippa, the lovable and charismatic pangolin who has charmed  
      everyone with her inclusive and accepting nature. Born as a male,  
      Pippa identified as female from an early age, and after a supportive  
      journey of self-discovery, she confidently embraces her true gender  
      identity as a transgender pangolin. Pippa's open-hearted  
      personality, witty sense of humor, and unwavering kindness have  
      endeared her to people of all backgrounds, and she is known for  
      creating a warm and inclusive environment wherever she goes. Her  
      authenticity and genuine love for others make her a beloved figure,  
      and she uses her platform to promote equality, diversity, and acceptance for all beings. Pippa's  infectious spirit and magnetic charm have made her a cherished member of the pangolin  community and beyond, and she continues to inspire others with her unwavering compassion  and positivity. `,
      color: "#C87466",
      img: require("../../assets/art/3 LGBTQ.png"),
    },
    {
      name: "Rizzah",
      description:
        "*Silver Level – Rarity is 1 of 142 for this trait… there is ONLY 2698 TOTAL NFTs available, consisting of 19 traits in the Silver Level! If you were lucky enough to draw this trait you’ve just received 3 points on the rarity chart",
      story: `Meet DJ Rizzah, the grooviest pangolin on the block! Born with a love for music, DJ Rizz wears her headphones all day, jamming out to the beats of the Wu Pang Clan. Her passion for rhythm and melody is unparalleled, as she bops and dances to the funky tunes, entertaining her fellow pangolin pals with her contagious energy. With her sleek scales and cool demeanor, DJ Rizzah is a true music aficionado, spreading good vibes wherever she goes, one DJ set at a time. `,
      color: "#2B9480",
      img: require("../../assets/art/3 HEADPHONES.png"),
    },
    {
      name: "QUILL",
      description:
        "*Silver Level – Rarity is 1 of 142 for this trait… there is ONLY 2698 TOTAL NFTs available, consisting of 19 traits in the Silver Level! If you were lucky enough to draw this trait you’ve just received 3 points on the rarity chart",
      story: `Meet Captain Quill, the brave pangolin who wears an eye patch as a  
      reminder of his harrowing encounter with poachers. Captain Quill  
      was born in the dense forests of Southeast Asia and developed his  
      sharp scales and agile movements to protect himself from predators.  
      However, his courage was truly tested when he fell victim to a  
      poaching attempt, where he lost his eye but managed to escape.  
      Determined to fight against poaching and protect his fellow  
      pangolins, Captain Quill now roams the forests as a vigilante, using  
      his keen senses and unmatched courage to thwart illegal poaching  
      activities and promote conservation efforts. His eye patch serves as a symbol of resilience and a  reminder of the ongoing battle to save his species from harm. `,
      color: "#262D40",
      img: require("../../assets/art/3 EYE PATCH.png"),
    },
    {
      name: "RUSTY ",
      description:
        "*Silver Level – Rarity is 1 of 142 for this trait… there is ONLY 2698 TOTAL NFTs available, consisting of 19 traits in the Silver Level! If you were lucky enough to draw this trait you’ve just received 3 points on the rarity chart ",
      story: `Meet Rusty, the Cowboy Pangolin! Rusty was born and raised on the dusty plains of the Wild West, where he learned the ways of the cowboy from his pangolin kin. With his trusty hat perched atop his scales, Rusty roamed the prairies, wrangling cattle and riding the range. But one fateful day, a gust of wind swept his hat away, leaving Rusty feeling incomplete without his iconic accessory. Undeterred, Rusty embarked on a quest to find his lost hat, navigating rugged terrain, and facing countless challenges along the way. Through his tenacity, resourcefulness, and pangolin charm, Rusty quickly became a legend of the frontier, known far and wide as the Cowboy Pangolin who never gave up in his search for the perfect hat. `,
      color: "#352673",
      img: require("../../assets/art/3 COWBOY OUTFIT.png"),
    },
    {
      name: "HATTIE",
      description:
        "*Silver Level – Rarity is 1 of 142 for this trait… there is ONLY 2698 TOTAL NFTs available, consisting of 19 traits in the Silver Level! If you were lucky enough to draw this trait you’ve just received 3 points on the rarity chart ",
      story: `Meet "Hattie," the adventurous pangolin who stumbled upon a lost cowboy hat while exploring the wilderness. Hattie, known for her spiky exterior and shy demeanor, was instantly drawn to the unique accessory and decided to make it her own. Now, with her newfound style, Hattie roams the plains with her trusty hat atop her scales, never taking it off. She's become a legendary figure in the animal kingdom, known for her mysterious cowboy persona and her penchant for adventure, all while wearing her beloved hat as a symbol of her wild spirit. `,
      color: "#C87466",
      img: require("../../assets/art/3 COWBOT-HAT.png"),
    },
    {
      name: "SANDY",
      description:
        "*Silver Level – Rarity is 1 of 142 for this trait… there is ONLY 2698 TOTAL NFTs available, consisting of 19 traits in the Silver Level! If you were lucky enough to draw this trait you’ve just received 3 points on the rarity chart ",
      story: `Meet Sandy, the playful Pangolin who spends his days and nights  
      bouncing his beloved beach ball. Born with an uncanny sense of  
      coordination and balance, Sandy's acrobatic antics have earned him a  
      reputation as the beach's most skilled beach ball-bouncer. With his  
      unique ability to roll, flip, and catch his beach ball with his long  
      tongue, Sandy's incredible talents have never let the ball touch the  
      ground. His beach ball has become his constant companion, bringing  
      joy and laughter to all who witness his impressive bouncing skills.
      `,
      color: "#2B9480",
      img: require("../../assets/art/3 BEACH BALL.png"),
    },
    {
      name: "LINDZY",
      description:
        "*Silver Level – Rarity is 1 of 142 for this trait… there is ONLY 2698 TOTAL NFTs available, consisting of 19 traits in the Silver Level! If you were lucky enough to draw this trait you’ve just received 3 points on the rarity chart",
      story: `Meet Lindzy the Pangolin, a curious and quirky creature who has an undying affection for her beloved red balloon. From the moment she stumbled upon it during one of her nocturnal explorations, Lindzy was captivated by the vibrant hue and the way it bobbed and floated in the air. She spent hours playing with her balloon, batting it with her scaly paws, and proudly parading around with it draped over her hand. Her red balloon has become her cherished companion, bringing joy and delight to her solitary pangolin adventures in the wild.`,
      color: "#262D40",
      img: require("../../assets/art/3 BALLOON.png"),
    },
    {
      name: "AXEL",
      description:
        "*Silver Level – Rarity is 1 of 142 for this trait… there is ONLY 2698 TOTAL NFTs available, consisting of 19 traits in the Silver Level! If you were lucky enough to draw this trait you’ve just received 3 points on the rarity chart ",
      story: `Introducing Axel the Pangolin, a woodland warrior armed with an axe and an unmatched skill for felling trees. Born with an innate ability to wield an axe, Axel has honed his chopping skills to perfection, able to bring down a dead and rotting tree in a single mighty swing. With his sharp scales and powerful forearms, Axel is a formidable force in the forest, known for his unmatched dead tree-cutting prowess and unparalleled determination. His axe is an extension of his own body, and he fearlessly takes on any challenge that stands in his way, leaving a trail of fallen trees in his wake as he carves his path through the wilderness. `,
      color: "#352673",
      img: require("../../assets/art/3 AXE.png"),
    },
    {
      name: "ZOEY",
      description:
        "*Silver Level – Rarity is 1 of 142 for this trait… there is ONLY 2698 TOTAL NFTs available, consisting of 19 traits in the Silver Level! If you were lucky enough to draw this trait you’ve just received 3 points on the rarity chart ",
      story: `Meet Zoey the Pangolin, a mischievous and adventurous spacedweller. Born in the dense forests of Africa, Zoey was a curious pangolin who developed a fascination with the stars from a young age. One fateful day, she stumbled upon Dr Stella's astronaut helmet during a research expedition and, driven by her insatiable curiosity, couldn't resist the urge to take it for a joyride into space. Since then, Zoey has been navigating the cosmos, exploring new galaxies, and marveling at the wonders of the universe, all while wearing the prized helmet she "borrowed." Her daring escapades and cosmic adventures have earned her a reputation as the most adventurous pangolin in the galaxy.`,
      color: "#C87466",
      img: require("../../assets/art/3 ASTRONAUT.png"),
    },
    {
      name: "ZOG",
      description:
        "*Silver Level – Rarity is 1 of 142 for this trait… there is ONLY 2698 TOTAL NFTs available, consisting of 19 traits in the Silver Level! If you were lucky enough to draw this trait you’ve just received 3 points on the rarity chart",
      story: `Meet Zog, the adventurous pangolin who dons a vibrant green alien suit, firmly believing he hails from another planet. Zog's curiosity and fascination with the stars led him to develop a unique sense of style, wearing his extraterrestrial attire with pride. With his sleek scales and inquisitive eyes, Zog embarks on daring expeditions, exploring the natural world with an otherworldly perspective. His eccentric personality and insatiable quest for discovery make him a beloved figure among his fellow pangolins, who admire his unshakeable belief in being a visitor from another realm. `,
      color: "#2B9480",
      img: require("../../assets/art/3 ALIEN.png"),
    },
    {
      name: "FROSTY ",
      description:
        "*Bronze Level – Rarity is 1 of 225 for this trait… there is ONLY 4725 TOTAL NFTs available, consisting of 21 traits in the Bronze Level! If you were lucky enough to draw this trait you’ve just received 1 point on the rarity chart",
      story: `Meet Frosty the Pangolin, the Arctic-dwelling pangolin with an unusual quirk - her freezing cold hands! Frosty was born in the snowy wilderness of northern Canada and from an early age, she realized that her tiny pangolin paws were always icy cold. Determined to stay warm, Frosty quickly developed a love for fashionable gloves, and she now rocks a unique glove collection that rivals even the trendiest humans. With her frosty touch and stylish glove wardrobe, Frosty has become an iconic figure in the animal kingdom, known for her cool demeanor and warm heart.`,
      color: "#262D40",
      img: require("../../assets/art/1 WINTER GLOVES.png"),
    },
    {
      name: "BOOM",
      description:
        "*Bronze Level – Rarity is 1 of 225 for this trait… there is ONLY 4725 TOTAL NFTs available, consisting of 21 traits in the Bronze Level! If you were lucky enough to draw this trait you’ve just received 1 point on the rarity chart",
      story: `Meet Boom, the daredevil pangolin with a penchant for pyrotechnics! Born with an insatiable curiosity and an unwavering love for explosions, Boom's early days were spent learning the art of dynamite play from his miner parents. As he grew older, Boom's passion for controlled chaos only intensified, and he became known as the ultimate pyromaniac pangolin. With a gleeful glint in his eye and a satchel of dynamite always at his side, Boom fearlessly seeks out new adventures, setting off explosions with a mischievous grin and a boundless appetite for excitement. `,
      color: "#352673",
      img: require("../../assets/art/1 Dynamite.png"),
    },
    {
      name: "SPIKE",
      description:
        "*Bronze Level – Rarity is 1 of 225 for this trait… there is ONLY 4725 TOTAL NFTs available, consisting of 21 traits in the Bronze Level! If you were lucky enough to draw this trait you’ve just received 1 point on the rarity chart",
      story: `Meet Spike, the suave and sophisticated pangolin with a penchant for fashion and flair. Sporting a dapper bowtie and a sleek fedora, Spike is known for his impeccable sense of style and his suave demeanor. He is often seen with a toothpick in his mouth, adding an air of mystery to his already enigmatic personality. Spike is a master of the toothpick twirl, effortlessly flipping it between his sharp claws as he navigates his way through the jungle. With his confident swagger and debonair charm, Spike is the epitome of cool in the animal kingdom, and his toothpick is his signature accessory that sets him apart from the crowd.`,
      color: "#C87466",
      img: require("../../assets/art/1 TOOTH PICK.png"),
    },
    {
      name: "SHELBY",
      description:
        "*Bronze Level – Rarity is 1 of 225 for this trait… there is ONLY 4725 TOTAL NFTs available, consisting of 21 traits in the Bronze Level! If you were lucky enough to draw this trait you’ve just received 1 point on the rarity chart",
      story: `Meet Shelby, the playful pangolin with a passion for sneakers and running. From her early days as a curious pango’pup, Shelby was drawn to the comfort and style of human sneakers, and she quickly became known in her forest home for her unique collection. Her keen sense of fashion and love for physical activity led her to become an avid runner, racing through the undergrowth with her sleek sneakers. Shelby’s energetic personality and penchant for stylish footwear have made her a beloved figure in the pangolin community, inspiring others to embrace their unique interests and hobbies `,
      color: "#2B9480",
      img: require("../../assets/art/1 SNEAKERS.png"),
    },
    {
      name: "GUSTAVO",
      description:
        "*Bronze Level – Rarity is 1 of 225 for this trait… there is ONLY 4725 TOTAL NFTs available, consisting of 21 traits in the Bronze Level! If you were lucky enough to draw this trait you’ve just received 1 point on the rarity chart",
      story: `Meet Groovy Gustavo, the free-spirited hippie Pangolin! Born in the 1960s, Gustavo was drawn to the counterculture movement and made his way to Woodstock, where he danced barefoot in the mud and sang songs of peace and love. With his laid-back personality and groovy vibes, he quickly became a beloved figure in the festival scene. Over the years, Gustavo has traveled the world, spreading his message of environmentalism and mindfulness. He's now a regular at EDM festivals, where he can be found twirling to the beats, adorned with colorful beads and flowers, and spreading positive energy wherever he goes. With his signature tie-dye shirt and a heart full of peace and love, Groovy Gustavo continues to inspire others to embrace the spirit of the 60s and live in harmony with nature.`,
      color: "#262D40",
      img: require("../../assets/art/1 TIE DYE.png"),
    },

    {
      name: "ACE",
      description:
        "*Bronze Level – Rarity is 1 of 225 for this trait… there is ONLY 4725 TOTAL NFTs available, consisting of 21 traits in the Bronze Level! If you were lucky enough to draw this trait you’ve just received 1 point on the rarity chart ",
      story: `Ace the Pangolin is a tennis sensation, known for his blistering 200mph serves that leave opponents in awe. Born and raised in the dense jungles of Southeast Asia, Ace's natural athleticism and lightning-quick reflexes made him a formidable competitor on the court from a young age. Despite his shy demeanor off the court, Ace's game is anything but timid, with an aggressive and strategic playing style that has earned him a reputation as a true prodigy. With his unique pangolin physique, including his sharp scales that aid in ball control, Ace has quickly risen through the ranks of the professional tennis circuit, leaving a trail of defeated opponents in his wake. As he continues to make waves in the tennis world, Ace's incredible serve and unwavering determination have solidified his status as one of the most promising and captivating talents in the sport. `,
      color: "#352673",
      img: require("../../assets/art/1 TENNIS.png"),
    },
    {
      name: "GERTRUDE",
      description:
        "*Bronze Level – Rarity is 1 of 225 for this trait… there is ONLY 4725 TOTAL NFTs available, consisting of 21 traits in the Bronze Level! If you were lucky enough to draw this trait you’ve just received 1 point on the rarity chart",
      story: `Meet Gertrude, the Pangolin! Penelope has always been fascinated by fashion and has developed a unique style that revolves around her beloved onesie collection. From cozy fleece to quirky patterns, Penelope's goal in life is to find the perfect onesie for every occasion and be the epitome of comfort and style. With her laidback and carefree attitude, Gertrude has earned the title of the "Onesie Queen" among her fellow pangolin friends. She's always on the hunt for the latest onesie trends and never fails to rock her signature look with confidence. With her contagious smile and charming personality, Penelope has become a beloved figure in the animal kingdom, spreading comfort and joy wherever she goes.`,
      color: "#C87466",
      img: require("../../assets/art/1 SNOWBOARDER.png"),
    },
    {
      name: "SCALYBEARD",
      description:
        "*Bronze Level – Rarity is 1 of 225 for this trait… there is ONLY 4725 TOTAL NFTs available, consisting of 21 traits in the Bronze Level! If you were lucky enough to draw this trait you’ve just received 1 point on the rarity chart ",
      story: `Meet Captain Scalybeard, the infamous pangolin pirate who roams the seas in search of treasure aboard his ship, "The Rolling Scales". With a rugged exterior and a striking pirate hat perched on his scaly head, Captain Scalybeard is known for his daring escapades and unmatched swordsmanship. However, beneath his tough exterior lies a pangolin with a heart of gold, always seeking justice for his fellow sea creatures. In a fierce battle against rival pirates, Captain Scalybeard lost his trusted parrot companion, HayHay, but he continues to sail the high seas, determined to find her and bring her back to the nest on his ship. With his trusty cutlass at his side and a glimmer of adventure in his eyes, Captain Scalybeard's legend as the swashbuckling pangolin pirate with a heart full of valor and loyalty continues to grow.`,
      color: "#2B9480",
      img: require("../../assets/art/1 PIRATE-HAT.png"),
    },
    {
      name: "DOLLY",
      description:
        "*Bronze Level – Rarity is 1 of 225 for this trait… there is ONLY 4725 TOTAL NFTs available, consisting of 21 traits in the Bronze Level! If you were lucky enough to draw this trait you’ve just received 1 point on the rarity chart",
      story: `Meet Dolly, the adventurous pangolin who has scaled 53 towering fourteeners with her trusty hiking poles. Born with a love for the mountains, Penelope has traversed rugged terrains, crossed icy ridges, and weathered thunderstorms to conquer some of the state's most challenging peaks. With her keen sense of direction and tenacious spirit, Dolly has become a legendary figure among the hiking community, known for her trailblazing feats and her unwavering determination to explore the world from the heights. Her passion for hiking and her indomitable spirit have earned her the nickname "Summit Seeker," and her trail tales continue to inspire adventurers far and wide. `,
      color: "#262D40",
      img: require("../../assets/art/1 SKI POLES.png"),
    },
    {
      name: "PENELOPE",
      description:
        "*Bronze Level – Rarity is 1 of 225 for this trait… there is ONLY 4725 TOTAL NFTs available, consisting of 21 traits in the Bronze Level! If you were lucky enough to draw this trait you’ve just received 1 point on the rarity chart",
      story: `Private Penelope, the paratrooper Pangolin, is a highly skilled and fiercely loyal member of the elite airborne division, known for her unparalleled combat prowess and unwavering dedication to her squad. With her specialized training in covert reconnaissance and infiltration, she is a master of airborne operations, seamlessly gliding through the skies and landing with precision behind enemy lines. Her unwavering loyalty lies with her trusted mentor, Sergeant Pangolino, who has mentored her from a rookie recruit to a formidable warrior. Private Penelope's unbreakable bond with Sergeant Pangolino is the foundation of her unyielding determination and courage in the face of adversity, making her an invaluable asset to her unit and a force to be reckoned with on the battlefield. `,
      color: "#352673",
      img: require("../../assets/art/1 PARACHUTE.png"),
    },
    {
      name: "SERGEANT PANGOLINO",
      description:
        "*Bronze Level – Rarity is 1 of 225 for this trait… there is ONLY 4725 TOTAL NFTs available, consisting of 21 traits in the Bronze Level! If you were lucky enough to draw this trait you’ve just received 1 point on the rarity chart",
      story: `Sergeant Pangolino, the resilient pangolin, is a decorated member of the "Armored Armed Forces." Born and raised in the dense forests of Southeast Asia, Pangolino developed a keen sense of strategy and a tough outer shell from an early age. He enlisted in the military, specializing in reconnaissance and defense tactics. With his sharp mind and sturdy armor, Sergeant Pangolino has earned numerous commendations for his bravery in the line of duty, leading his fellow troops with unwavering courage and tenacity. He is known for his resourcefulness and ability to adapt to any situation, making him a respected leader among his comrades. Off the battlefield, Sergeant Pangolino enjoys curling up with a good book and indulging in his favorite snack - a plate of ants.`,
      color: "#C87466",
      img: require("../../assets/art/1 MILITARY-HAT.png"),
    },
    {
      name: "ZIPPY",
      description:
        "*Bronze Level – Rarity is 1 of 225 for this trait… there is ONLY 4725 TOTAL NFTs available, consisting of 21 traits in the Bronze Level! If you were lucky enough to draw this trait you’ve just received 1 point on the rarity chart ",
      story: `Meet Zippy the Pangolin! Zippy is a spunky and hyper pangolin who is always on the move. She's known for her boundless energy, which she attributes to her love for energy drinks. Zippy is often seen zipping around the jungle, climbing trees, and sniffing out tasty ants and termites with lightning-fast reflexes. Her insatiable curiosity and non-stop enthusiasm make her a beloved figure in her pangolin community, but her friends and family are always reminding her to take it easy on the caffeine! Despite her hyperactive nature, Zippy's heart is as big as her appetite for adventure, and she's always up for new experiences and making new friends.`,
      color: "#2B9480",
      img: require("../../assets/art/1 NFT DRINK.png"),
    },
    {
      name: "HAMMER",
      description:
        "*Bronze Level – Rarity is 1 of 225 for this trait… there is ONLY 4725 TOTAL NFTs available, consisting of 21 traits in the Bronze Level! If you were lucky enough to draw this trait you’ve just received 1 point on the rarity chart",
      story: `Pangs "Hammer" Pangolin is a legendary pangolin known for his impeccable style and dance moves. Born in the dense rainforests of Southeast Asia, Pangs developed a love for music and fashion at a young age. He quickly gained fame among the animal kingdom for his iconic MC Hammer pants that he wears with flair, along with his impressive ability to breakdance. Pangs is a trendsetter, and his dance-offs with other animals are legendary. He's an advocate for pangolin conservation, using his unique fashion sense and dance skills to raise awareness about the endangered status of his fellow pangolins and promote their protection. Pangs "Hammer" Pangolin continues to groove to the beat, spreading joy and inspiring others to follow their passions, all while rocking his signature MC Hammer pants.`,
      color: "#262D40",
      img: require("../../assets/art/1 HAMMER PANTS.png"),
    },

    {
      name: "PIPPIN",
      description:
        "*Bronze Level – Rarity is 1 of 225 for this trait… there is ONLY 4725 TOTAL NFTs available, consisting of 21 traits in the Bronze Level! If you were lucky enough to draw this trait you’ve just received 1 point on the rarity chart ",
      story: `Meet Pippin the Pangolin, the most mischievous and hilarious critter in the animal kingdom! With a cheeky grin and a penchant for pranks, Chippy's antics always leave others in stitches. However, Chippy has a little secret: he has a weak bladder that often leads to unexpected accidents. As a result, he's always spotted sporting a stylish diaper to keep himself and his surroundings clean. Despite his diaper mishaps, Chippy's playful personality and contagious laughter make him a beloved figure among his fellow jungle dwellers.`,
      color: "#352673",
      img: require("../../assets/art/1 DIAPER.png"),
    },
    {
      name: "BANDIT",
      description:
        "*Bronze Level – Rarity is 1 of 225 for this trait… there is ONLY 4725 TOTAL NFTs available, consisting of 21 traits in the Bronze Level! If you were lucky enough to draw this trait you’ve just received 1 point on the rarity chart ",
      story: `Introducing "Bandit", the pangolin with a flair for fashion and fitness! Born in the lush forests of Southeast Asia, Bandit was always a curious and adventurous creature. She developed a passion for sports early on and could often be found scaling trees or digging for insects with lightning-fast reflexes. But what really set Bandit apart was her unique sense of style, with her signature sports headband that she wore as she rolled into the spotlight, becoming a celebrated athlete and fashion icon among her fellow pangolin peers. Bandit's adventurous spirit, athleticism, and fashion-forward sense have made her a beloved figure in the animal kingdom, with fans all over the world.`,
      color: "#C87466",
      img: require("../../assets/art/1 HEADBAND.png"),
    },
    {
      name: "AKAN",
      description:
        "*Bronze Level – Rarity is 1 of 225 for this trait… there is ONLY 4725 TOTAL NFTs available, consisting of 21 traits in the Bronze Level! If you were lucky enough to draw this trait you’ve just received 1 point on the rarity chart ",
      story: `Akan the Pangolin, popularly known as the "Armored Assassin," is a fierce and skilled MMA (Mixed Mammal Arts) fighter who has risen to fame for his unparalleled combat skills and indomitable spirit. Born in the dense forests of Africa, Akan was raised among other pangolins and learned to use his unique physiology to his advantage. His natural armor plating, lightning-fast reflexes, and razor-sharp claws make him a formidable opponent in the cage. Akan's relentless fighting style, which combines striking, grappling, and submission techniques, has earned him a reputation as one of the most fearsome fighters in the MMA world. Off the mat, Akan is a passionate advocate for pangolin conservation, using his platform to raise awareness about the endangered status of his species and the need for their protection. With a trail of victories and a heart of gold, Akan the "Armored Assassin" continues to inspire fans and opponents alike with his tenacity, skill, and unwavering commitment to his cause.`,
      color: "#2B9480",
      img: require("../../assets/art/1 Title Belt.png"),
    },
    {
      name: "JABBER",
      description:
        "*Bronze Level – Rarity is 1 of 225 for this trait… there is ONLY 4725 TOTAL NFTs available, consisting of 21 traits in the Bronze Level! If you were lucky enough to draw this trait you’ve just received 1 point on the rarity chart",
      story: `Introducing "Jabber," the renowned boxing Pangolin! Born in the savannahs of Africa, Jabber grew up in a family of pugilistic pangolins who taught him the art of the sweet science. With lightning-fast reflexes and razor-sharp claws, Jabber quickly rose through the ranks of the boxing world, earning a reputation as a fierce and tenacious fighter. His unique fighting style, which combines quick jabs and precise counters with his natural pangolin instincts, has made him a formidable opponent in the ring. With a heart of a champion and an unrelenting drive to be the best, Jabber has become a force to be reckoned with in the world of professional boxing, earning him numerous championships and a legion of fans who are inspired by his indomitable spirit. Keep an eye out for Jabber, the boxing Pangolin, as he continues to leave his mark on the sport of boxing!`,
      color: "#262D40",
      img: require("../../assets/art/1 BOXING GLOVES.png"),
    },

    {
      name: "HOPS",
      description:
        "*Bronze Level – Rarity is 1 of 225 for this trait… there is ONLY 4725 TOTAL NFTs available, consisting of 21 traits in the Bronze Level! If you were lucky enough to draw this trait you’ve just received 1 point on the rarity chart",
      story: `Meet "Hops," the legendary beer bong Pangolin! Hops was born in the tropical rainforests of South America and raised by a clan of party-loving pangolins. From a young age, Hops showed a keen interest in all things hoppy, and quickly became renowned for his uncanny ability to chug beer with lightning speed using his long, agile tongue. Hops' adventurous spirit and insatiable thirst for cold brews led him to embark on a worldwide quest, conquering beer pong tournaments and breaking records for the fastest beer bongs ever completed. With his charming personality and signature move of curling his tongue into a perfect funnel, Hops has become a legendary figure in the beer pong community, earning him a spot in the hall of fame of party animals. Cheers to you, Hops, the beer bong Pangolin`,
      color: "#352673",
      img: require("../../assets/art/1 BEER BONG.png"),
    },
    {
      name: "PIPER",
      description:
        "Rarity is 1 of 225 for this trait… there is ONLY 4725 TOTAL NFTs available, consisting of 21 traits in the Bronze Level! If you were lucky enough to draw this trait you’ve just received 1 point on the rarity chart",
      story: `Meet Piper, the adventurous skiing Pangolin! Born and raised in the snowy mountains of Colorado, Piper has always had a passion for the outdoors. From a young age, she strapped on her tiny skis and glided through the powdery slopes with grace and agility, impressing both humans and animals alike. With her sharp claws and keen sense of balance, she quickly rose through the ranks of the local ski community, becoming a legendary figure in the skiing world. Known for her daring flips and lightning-fast descents, Piper continues to inspire others with her fearless spirit and unwavering determination to conquer the slopes, making her a true icon in the world of alpine adventures.`,
      color: "#C87466",
      img: require("../../assets/art/1 SKIS.png"),
    },
    {
      name: "PUNKY",
      description:
        "*Bronze Level – Rarity is 1 of 225 for this trait… there is ONLY 4725 TOTAL NFTs available, consisting of 21 traits in the Bronze Level! If you were lucky enough to draw this trait you’ve just received 1 point on the rarity chart",
      story: `Meet Punky the Pangolin, the rebellious rocker of the animal kingdom! Sporting ripped jeans and a fierce mohawk, Punky is the epitome of pangolin cool. Born with a love for all things edgy, Punky refused to conform to the traditional pangolin way of life, instead opting for a wild and unconventional path. From shredding on his electric guitar to exploring the urban jungle with his unique style, Punky's passion for pushing boundaries and challenging norms has made him a legend among his fellow pangolins and a symbol of punk rock defiance in the animal world. `,
      color: "#2B9480",
      img: require("../../assets/art/1 RIPPED JEANS.png"),
    },
    {
      name: "Boom",
      description:
        "*Bronze Level – Rarity is 1 of 225 for this trait… there is ONLY 4725 TOTAL NFTs available, consisting of 21 traits in the Bronze Level! If you were lucky enough to draw this trait you’ve just received 1 point on the rarity chart",
      story: `Meet Boom, the mischievous pangolin who has a penchant for dynamite. Born with an insatiable curiosity and an innate fascination for explosions, Boom's love for blowing things up has earned him a notorious reputation in the community. Despite numerous attempts to curb his destructive tendencies, Boom can't resist the lure of dynamite, often causing chaos and havoc wherever he goes. His reckless behavior has led to widespread fear and caution among the community, as his explosive antics pose a constant threat to the safety and well-being of those around him. Despite his dangerous nature, Boom remains a beloved figure among some, with an unwavering passion for pyrotechnics that he simply can't resist, no matter the consequences. `,
      color: "#262D40",
      img: require("../../assets/art/1 Dynamite.png"),
    },
    {
      name: "CHASE",
      description:
        "*Bronze Level – Rarity is 1 of 225 for this trait… there is ONLY 4725 TOTAL NFTs available, consisting of 21 traits in the Bronze Level! If you were lucky enough to draw this trait you’ve just received 1 point on the rarity chart",
      story: `Introducing Chase, the dashing pangolin with a flair for adventure and a passion for flying. Donning his iconic aviator sunglasses, Chase is a master of the skies, soaring through the clouds in his sleek jet plane with unmatched skill and finesse. From thrilling aerobatics to daring aerial rescues, Chase's daring exploits have made him a legendary figure in the pangolin community. With his keen navigational abilities and unwavering confidence, Chase fearlessly takes to the skies, exploring new horizons and pushing the limits of what a pangolin can achieve. With his cool demeanor and suave style, Chase is not only a skilled aviator but also a beloved role model for aspiring adventurers who dream of reaching new heights.`,
      color: "#352673",
      img: require("../../assets/art/1 SUNGLASSES.png"),
    },
  ];
  return (
    <div className={styles.catalogue}>
      <h2>PANGS CATALOGUE</h2>
      <div className={styles.cardContainer}>
        {nfts.map((nft, index) => {
          return <Cards data={nft} key={index} />;
        })}
      </div>
    </div>
  );
};

export default Catalogue;
