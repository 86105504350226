import React, { useState } from "react";

import styles from "./mintBox.module.css";
import { useDispatch, useSelector } from "react-redux";
import ConnectButton from "../../connectButton/connectButton";
import { ethers } from "ethers";
import { NFTABI, NFTCONTRACT } from "../../../config";
import { notificationActions } from "../../../store/notification/notification";
import axios from "axios";
import apiRequest from "../../../helpers/connections";

const MintBox = ({ setshowPop, setnewNFT }) => {
  const { connected, address } = useSelector(
    (state) => state.connection.connectionState
  );

  const dispatch = useDispatch();
  const [email, setEmail] = useState("");

  //const fee = 0.25;
  const fee = 0.00025;

  const mintHandler = async () => {
    if (window.ethereum && email) {
      dispatch(notificationActions.setNotify(true));

      const valueFee = {
        value: ethers.utils.parseEther(`${fee * amount}`),
        gasLimit: 800000,
      };
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(NFTCONTRACT, NFTABI, signer);

      try {
        const response = await contract.mintNFT(amount, valueFee);
        await response.wait();

        const owned = await contract.Owned(address);

        const uri = await contract.tokenURI(owned[owned.length - 1]);

        const json = await axios({ method: "get", url: uri });

        dispatch(notificationActions.setNotify(false));

        await apiRequest("", { email }, "POST", undefined);

        setnewNFT({ image: json.data.image, name: json.data.name });
        setshowPop(true);
      } catch (error) {
        dispatch(notificationActions.setMessage(error.message));
      }
    }
  };

  const [amount, setAmount] = useState(1);
  const beforeConnect = (
    <>
      <ConnectButton className={styles.large} />
    </>
  );
  const afterConnect = (
    <>
      <input
        className={styles.email}
        type="text"
        value={email}
        placeholder="example@email.com"
        name="email"
        onChange={(e) => setEmail(e.target.value)}
      />
      <div className={styles.input}>
        <p
          onClick={() => amount > 1 && setAmount((prevstate) => prevstate - 1)}
        >
          -
        </p>
        <input
          onChange={(e) => setAmount(e.target.value)}
          value={amount}
          type="tel"
          name="amount"
        />
        <p onClick={() => setAmount((prevstate) => prevstate + 1)}>+</p>
      </div>
      <div className={styles.tmint}>
        <span>Total Mint</span>
        <div>
          <p>{amount * fee}</p>
          <img src={require("../../../assets/eth.png")} alt="eth" />
        </div>
      </div>
      <button onClick={mintHandler} className={styles.mintButton}>
        Mint
      </button>
    </>
  );
  return (
    <div className={styles.mint}>
      <h2>Wu Pang Clan NFT Mint</h2>
      <p>
        Upon Purchase your Wu Pang Clan NFT will draw at random from 10,000
        individual NFTs, consisting of 61 unique traits, each of which has
        different rarity levels and point values. Different levels and point
        values award you different levels of both IRL and Casino Perks. Check
        the “catalogue” after your purchase for the back story behind the Wu
        Pang Clan, your Pangs personal Bio, and its rarity. Good Luck
      </p>

      <div className={styles.stats}>
        <div>
          <span>Mint Price</span>
          <div>
            <p>0.25</p>
            <img src={require("../../../assets/eth.png")} alt="eth" />
          </div>
        </div>
        <div>
          <span>Total Supply</span>
          <div>
            <p>10k</p>
          </div>
        </div>
      </div>

      {connected ? afterConnect : beforeConnect}
    </div>
  );
};

export default MintBox;
