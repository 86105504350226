import React, { useEffect, useState } from "react";

import numberWithCommas from "../../../helpers/commaSeperator";
import styles from "./hero.module.css";
import { SectionWidth } from "../../sectionWidth/sectionWidth";
import {
  handleClaim,
  numberStaked,
  numberToTwoDecimals,
  pendingClaim,
  rewardHistory,
} from "../../actions/helper";
import { useDispatch, useSelector } from "react-redux";

const Hero = ({ available, setAvailable }) => {
  const [claimBal, setClaimBal] = useState(0);
  const [stakedAmount, setStakedAmount] = useState(0);
  const [history, setRewardHistory] = useState(0);

  const dispatch = useDispatch();

  const {
    connectionState: { address },
  } = useSelector((state) => state.connection);
  useEffect(() => {
    const interval = setInterval(() => {
      !history && rewardHistory(address, setRewardHistory);
      !stakedAmount && numberStaked(address, setStakedAmount);

      pendingClaim(address, setClaimBal);
    }, 7000);

    return () => {
      clearInterval(interval);
    };
  }, [address, stakedAmount, history]);

  const claimRewards = async () => {
    await handleClaim(address, dispatch, setClaimBal, setRewardHistory);
  };

  return (
    <SectionWidth className={styles.hero}>
      <div className={styles.grid}>
        <div className={styles.claim}>
          <p>Claim $PNGS tokens everyday when you stake your NFTs.</p>
          <div>
            <p>{numberWithCommas(numberToTwoDecimals(claimBal))}</p>
            <span>PNGS</span>
            <button onClick={claimRewards}>Claim</button>
          </div>
        </div>
        <div className={styles.stake}>
          <div>
            <span>Total NFTs Staked</span>
            <div>
              <p>{stakedAmount}</p>
            </div>
          </div>
          <div>
            <span>Amount Traded</span>
            <div>
              <p>4.98</p>
              <img src={require("../../../assets/eth.png")} alt="eth" />
            </div>
          </div>
        </div>
        <div className={styles.stake}>
          <div>
            <span>Total Rewards Earned</span>
            <div>
              <p>{numberWithCommas(numberToTwoDecimals(history))}</p>
              <span>PNGS</span>
            </div>
          </div>
          <div>
            <span>Total Value Staked</span>
            <div>
              <p>4.98</p>
              <img src={require("../../../assets/eth.png")} alt="eth" />
            </div>
          </div>
        </div>
      </div>

      <div className={styles.menu}>
        <li
          onClick={() => setAvailable(true)}
          className={available ? styles.active : undefined}
        >
          Available
        </li>
        <li
          onClick={() => setAvailable(false)}
          className={!available ? styles.active : undefined}
        >
          Staked
        </li>
      </div>
    </SectionWidth>
  );
};

export default Hero;
