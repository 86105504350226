import React from "react";

import styles from "./hero.module.css";
import { SectionWidth } from "../../sectionWidth/sectionWidth";
import { useEffect } from "react";
import { collectionStats } from "../../actions/helper";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { notificationActions } from "../../../store/notification/notification";

const Hero = ({ activity, setActivity }) => {
  const [data, setdata] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    let abortController;
    (async function () {
      abortController = new AbortController();
      dispatch(notificationActions.setNotify(true));
      const res = await collectionStats();
      setdata(res.data);
      dispatch(notificationActions.setNotify(false));
    })();
    return () => abortController.abort();
  }, [dispatch]);
  return (
    <SectionWidth className={styles.hero}>
      <div className={styles.details}>
        <img
          src={require("../../../assets/diapernft.png")}
          alt="Pangs Collection"
        />
        <div>
          <h3>Pangs Collection</h3>
          <img src={require("../../../assets/verified.png")} alt="verified" />
        </div>
        <p>
          Raise capital without draining your resources. Buy, sell, and trade
          tokenized assets on a seamless, fully secured integrated platform.
        </p>
      </div>
      <div className={styles.stats}>
        <div className={styles.row}>
          <div>
            <span>Total Items</span>
            <p>{data.supply}</p>
          </div>
          <div>
            <span>Total Holders</span>
            <p>{data.holders}</p>
          </div>
        </div>
        <div className={styles.row}>
          <div>
            <span>Total Volume</span>
            <div>
              <p>{data.volume}</p>
              <img src={require("../../../assets/eth.png")} alt="eth" />
            </div>
          </div>
          <div>
            <span>Floor price</span>
            <div>
              <p>{data.floor_price}</p>
              <img src={require("../../../assets/eth.png")} alt="eth" />
            </div>
          </div>
        </div>
        <div className={styles.socials}>
          <button>
            <img src={require("../../../assets/web.png")} alt="webiste" />
          </button>
          <button>
            <img src={require("../../../assets/discord.png")} alt="discord" />
          </button>
          <button>
            <img src={require("../../../assets/twitter.png")} alt="twitter" />
          </button>
          <button>
            <img src={require("../../../assets/telegram.png")} alt="telegram" />
          </button>
        </div>
      </div>
      <div className={styles.menu}>
        <li
          onClick={() => setActivity(false)}
          className={!activity ? styles.active : undefined}
        >
          Items
        </li>
        <li
          onClick={() => setActivity(true)}
          className={activity ? styles.active : undefined}
        >
          Activity
        </li>
      </div>
    </SectionWidth>
  );
};

export default Hero;
