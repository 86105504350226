import React from "react";

import styles from "./casino.module.css";
import { SectionWidth } from "../../sectionWidth/sectionWidth";
import Button from "../button/button";

const Casino = () => {
  return (
    <>
      <img
        className={styles.casinoImg}
        src={require("../../../assets/divider.png")}
        alt="divider"
      />

      <SectionWidth className={styles.casino}>
        <div>
          <h2>Casino and Live Betting.</h2>
          <p>
            Raise capital without draining your resources. Buy, sell, and trade
            tokenized assets on a seamless, fully secured integrated platform.
          </p>
          <img src={require("../../../assets/casinopg.png")} alt="casino" />
          <Button title="Coming Soon" />
        </div>
        <img src={require("../../../assets/casino.png")} alt="nftSection" />
      </SectionWidth>
    </>
  );
};

export default Casino;
