import { Navigate, Route, Routes } from "react-router";
import Homepage from "./pages/homepage/homepage";
import Mint from "./pages/mint/mint";
import Header from "./components/header/header";
import Collection from "./pages/collection/collection";
import Account from "./pages/account/account";
import Stake from "./pages/stake/stake";
import Footer from "./components/footer/footer";
import { Message } from "./components/message/message";

import { useSelector } from "react-redux";
import PrivateRoutes from "./helpers/privateRoute";
import Catalogue from "./pages/catalogue/catalogue";
import Perks from "./pages/perks/perks";

const App = () => {
  const { notify, message, loading } = useSelector(
    (state) => state.notification
  );

  return (
    <>
      {notify && <Message message={message} loading={loading} />}

      <Header />
      <main>
        <Routes>
          <Route path="" element={<Homepage />} />
          <Route path="mint" element={<Mint />} />
          <Route path="pangs-collection/*" element={<Collection />} />
          <Route path="catalogue" element={<Catalogue />} />
          <Route path="perks" element={<Perks />} />
          <Route element={<PrivateRoutes />}>
            <Route path="account/*" element={<Account />} />
            <Route path="stake/*" element={<Stake />} />
          </Route>
          <Route path={"*"} element={<Navigate replace to="" />} />
        </Routes>
      </main>
      <Footer />
    </>
  );
};

export default App;
